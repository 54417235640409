.activity-allocation-grid {
  width: clamp(720px, 100%, 1536px);
}

.no-data-found {
  border-radius: 16px;
  padding: 4px;
}

.activity-allocation-grid > * {
  margin-bottom: 24px;
  box-shadow: 0px 1px 14px 0px #00071624;
  padding-bottom: 5px;
}

.activity-allocation-grid > *:last-child {
  margin-bottom: 0; /* Remove margin from the last element */
}

.employee-section-header-alias {
  color: #5f6b7a;
}

.pagination-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
