.project-header-container {
  width: 100%;
  display: flex;
}

.project-header-title {
  line-height: 2.5;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-document-button {
  margin-left: auto;
}

.project-document-box {
  border-radius: 16px;
  box-shadow: 0px 1px 14px 0px #00071624;
}

.hidden {
  opacity: 0;
}

.polarisInputButton {
  position: absolute;
}

.inputButton {
  z-index: 1;
  width: 100%;
  height: 100%;
  margin-left: -29%;
  margin-top: -3%;
  position: absolute;
}

.margin-top-10 {
  margin-top: 10px;
}

.project-documents-row {
  display: inline-block;
  position: absolute;
  right: 1rem;
}

.header-container {
  display: flex;
}
