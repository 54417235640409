.table-title-subheading {
  color: #414d5c;
}

.table-header {
  width: 27vw;
  height: 50px;
  padding: 4px 0px 0px 0px;
  gap: 2px;
  text-overflow: 'wrap';
}

.search-input {
  width: 30vw;
}
