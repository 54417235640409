.project-row {
  display: inline-block;
  position: absolute;
  right: 3rem;
}

.delete-button {
  margin-right: 10px;
}

.add-project-container {
  margin: auto;
  display: flex;
}

.add-project-row {
  margin: auto;
}
.project-container {
  display: flex;
}

.project-title-bar {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.project-step ol {
  overflow: scroll;
  max-height: 40vh;
  height: 40vh;
}

.project-step ol li {
  max-height: 120px;
}

.project-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
