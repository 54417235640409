.wizard-description {
  display: flex;
  width: clamp(720px, 100%, 1536px);
  align-items: flex-start;
  gap: 32px;
  margin: 0 auto;
}

.description-text {
  width: clamp(540px, 60%, 720px);
  color: var(--grey-900, #000716);
  font-family: 'Amazon Ember';
}

.info-box {
  display: flex;
  width: 432px;
  min-width: 340px;
  align-items: flex-start;
  flex: 1 0 0;
}
