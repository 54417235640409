.employee-review-page {
  width: clamp(720px, 100%, 1536px);
}

.search-input {
  width: 30vw;
}

.pagination-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.box-shadow {
  border-radius: 16px;
  box-shadow: 0px 1px 14px 0px #00071624;
}
