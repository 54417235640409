@use './base';

.custom-input-small {
  max-width: 11rem;
  display: inline-block;
}

.overlay {
  position: fixed;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  cursor: pointer;
}

.spinner-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.wizard-section {
  width: clamp(1004px, 100%, 1536px);
  margin-right: 100px;
}

.custom-divider {
  border-left: 1px solid #d8d8d8;
  margin: 0 10px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.survey-page-header-info {
  display: flex;
  align-items: center;
  width: clamp(1004px, 100%, 1536px);
  justify-content: space-between;
}

.survey-page-header-info-sub-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
