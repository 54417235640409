.percent-input-box-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-title-subheading {
  color: #414d5c;
}

.employee-description {
  color: #414d5c;
  font-size: 12px;
}

.search-input {
  width: 30vw;
}

.percent-sign {
  display: flex;
  align-items: center;
}

.percent-input-box {
  width: 82px;
  margin-right: 0px;
}

.render-cell {
  align-items: center;
}

@media (max-width: 1200px) {
  .percent-input-box {
    width: 76px; /* Adjust width for medium screens */
  }
}

@media (max-width: 768px) {
  .percent-input-box {
    width: 68px; /* Adjust width for small screens */
  }
}
