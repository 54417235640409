.page {
    max-width: 1800px;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    margin: auto;
}

.app-content-div {
    margin: auto;
}

.position-relative {
    position: relative;
}

.spinner-div {
    position: absolute;
    z-index: 5001;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: white;
}

.spinner-translation-div {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(50%, 30%);
}

